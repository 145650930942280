import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-lg-9" }
const _hoisted_4 = { class: "mb-0" }
const _hoisted_5 = { class: "col-lg-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_popover = _resolveComponent("ion-popover")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, { class: "whiteBackground ion-padding" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.t("account.openTicket")), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("a", {
              href: "javascript:void(0)",
              class: "close ion-float-end",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.props.dismiss()))
            }, [
              _createVNode(_component_ion_icon, {
                icon: _ctx.icons.close,
                color: "danger"
              }, null, 8, ["icon"])
            ])
          ])
        ]),
        _createElementVNode("form", {
          onSubmit: _cache[6] || (_cache[6] = ($event: any) => (_ctx.addClaimSubmit($event))),
          id: "addClaimForm",
          enctype: "multipart/form-data"
        }, [
          _withDirectives(_createElementVNode("input", {
            type: "hidden",
            name: "certificate_id",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.claimData.certificate_id) = $event))
          }, null, 512), [
            [_vModelText, _ctx.claimData.certificate_id]
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "hidden",
            name: "incident_date",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.claimData.incident_date) = $event))
          }, null, 512), [
            [_vModelText, _ctx.claimData.incident_date]
          ]),
          _createVNode(_component_ion_list, { class: "formList" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, {
                button: "true",
                id: "open-date-input",
                class: _normalizeClass({
              'is-invalid': _ctx.vAddClaim.incident_date.$errors.length,
            })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, {
                    textContent: _toDisplayString(_ctx.t('warranty.incidentDate'))
                  }, null, 8, ["textContent"]),
                  _createVNode(_component_ion_text, {
                    slot: "end",
                    textContent: _toDisplayString(_ctx.claimData.incident_date)
                  }, null, 8, ["textContent"]),
                  _createVNode(_component_ion_popover, {
                    trigger: "open-date-input",
                    "show-backdrop": false,
                    class: "editPricePopOver"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_datetime, {
                        presentation: "date",
                        onIonChange: _cache[3] || (_cache[3] = ($event: any) => (
              _ctx.dateChanged($event)
              )),
                        ref: "incidentDateCalendar"
                      }, null, 512)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_ion_item, {
                class: _normalizeClass(["form-control", {
              'is-invalid': _ctx.vAddClaim.description.$errors.length,
            }]),
                lines: "none"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, {
                    textContent: _toDisplayString(_ctx.t('warranty.description'))
                  }, null, 8, ["textContent"]),
                  _createVNode(_component_ion_textarea, {
                    name: "description",
                    modelValue: _ctx.claimData.description,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.claimData.description) = $event)),
                    placeholder: _ctx.t('warranty.description')
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_ion_item, {
                class: "form-control",
                lines: "none"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, {
                    textContent: _toDisplayString(_ctx.t('warranty.image'))
                  }, null, 8, ["textContent"]),
                  _createVNode(_component_ion_input, {
                    class: "addClaimAttachment",
                    name: "image",
                    placeholder: _ctx.t('warranty.image'),
                    type: "file",
                    accept: "image/png, image/jpeg"
                  }, null, 8, ["placeholder"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, {
            class: "text-center",
            "responsive-sm": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, { size: "12" }, {
                default: _withCtx(() => [
                  _createElementVNode("button", {
                    type: "submit",
                    class: "btn btn-heading btn-block",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.addClaimSubmit($event)))
                  }, _toDisplayString(_ctx.t("login.submit")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ], 32)
      ])
    ]),
    _: 1
  }))
}