
import { defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { close } from "ionicons/icons";
import settingsApp from "@/libs//settings";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import httpApp from "@/libs/http";
import { format, parseISO } from "date-fns";

import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonTextarea,
  IonRow,
  IonCol,
  IonIcon,
  IonPopover,
  IonDatetime
} from "@ionic/vue";
export default defineComponent({
  name: "OpenTicketModal",
  props: {
    certificate_id: Number,
    dismiss: Function,
  },
  setup(props: any) {
    const { t, te } = useI18n();
    const { createLoading, openToast } = useGlobalFunctions();
    const icons = reactive({
      close,
    });

    const claimData = reactive({
      certificate_id: props.certificate_id,
      incident_date: '',
      description: "",
    });
    const claimDataRules = {
      certificate_id: { required },
      incident_date: { required },
      description: { required },
    };

    const vAddClaim = useVuelidate(claimDataRules, claimData) as any;

    const addClaimSubmit = async (event) => {
      event.preventDefault();
      vAddClaim.value.$touch();
      if (vAddClaim.value.$invalid) {
        return false;
      }

      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      const addClaimForm = document.querySelector(
        "#addClaimForm"
      ) as HTMLFormElement;
      const formData = new FormData(addClaimForm);

      const attchmentInput = document.querySelector(
        ".addClaimAttachment input"
      ) as HTMLInputElement;
      for (const file of attchmentInput.files) {
        formData.append("image", file);
      }

      httpApp
        .sendPostFormData(
          settingsApp.getEndpointUrl("addCustomerClaim"),
          formData
        )
        .then(async (response: any) => {
          if (response.success) {
            props.dismiss();
          } else {
            if (te("apiMsgs." + response.error.code)) {
              openToast(t("apiMsgs." + response.error.code), "danger");
            } else {
              openToast(response.error.message, "danger");
            }
          }
          loading.dismiss();
        })
        .catch((error) => {
          loading.dismiss();
          console.log(error);
        });
    };

    const incidentDateCalendar = ref();

    const formatDate = (value: string) => {
      return format(parseISO(value), "MMM dd yyyy");
    };

    const dateChanged = (event) => {
      claimData.incident_date = formatDate(event.detail.value);
      incidentDateCalendar.value.$el.confirm(true);
    };

    return {
      t,
      props,
      icons,
      claimData,
      vAddClaim,
      dateChanged,
      incidentDateCalendar,
      addClaimSubmit,
    };
  },
  components: {
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonTextarea,
    IonRow,
    IonCol,
    IonIcon,
    IonPopover,
    IonDatetime
  },
});
