
import {
  IonPage,
  IonContent,
  modalController
} from "@ionic/vue";
import { timeOutline, closeOutline } from "ionicons/icons";
import { defineComponent, ref, reactive } from "vue";
import { useI18n } from "vue-i18n";
import settingsApp from "@/libs/settings";
import httpApp from "@/libs/http";
import { useStore } from "@/store";
import { useRoute } from "vue-router";

import AccountNavComponent from "./AccountNavComponent.vue";
import AddcertificateClaimModal from "@/components/AddcertificateClaimModal.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import Pagination from "@/components/Pagination.vue";

export default defineComponent({
  name: "WarrantyCertificates",
  setup() {
    const { t, tm } = useI18n();
    const storeX = useStore();
    const route = useRoute();
    const icons = reactive({
      timeOutline,
      closeOutline,
    });
    const certificateId = ref();
    const certificate = ref();
    const claims = ref([]);
    const limit = ref(20);
    const currentPage = ref(1);
    const pages = ref(0);

    const getCustomerCertificateById = () => {
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getCustomerCertificateById"), {
          certificate_id: certificateId.value,
        })
        .then(async (response: any) => {
          if (!Object.prototype.hasOwnProperty.call(response, "error")) {
            certificate.value = response.data;
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    const getCustomerClaims = (page = 1) => {
      currentPage.value = page;
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getCustomerClaims"), {
          certificate_id: certificateId.value,
          limit: limit.value,
          page: currentPage.value,
        })
        .then(async (response: any) => {
          if (!Object.prototype.hasOwnProperty.call(response, "error")) {
            claims.value = response.items;
            pages.value = response.pager.pages;
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    const openAddClaim = async () => {
      const modal = await modalController.create({
        component: AddcertificateClaimModal,
        cssClass: "AddcertificateClaimModal",
        componentProps: {
          certificate_id: certificateId.value,
          dismiss: () => {
            modal.dismiss();
            getCustomerClaims();
          },
        },
      });
      return modal.present();
    }

    const formateDate = (date: any) => {
      const dateArr = date.split(" ");
      return dateArr[0];
    };

    function ionViewWillEnter() {
      claims.value = [];
      limit.value = 20;
      currentPage.value = 1;
      pages.value = 0;
      certificateId.value = route.params.certificateId;
      getCustomerCertificateById();
      getCustomerClaims();
    }
    return {
      t,
      tm,
      storeX,
      icons,
      claims,
      ionViewWillEnter,
      formateDate,
      pages,
      currentPage,
      certificateId,
      certificate,
      getCustomerClaims,
      openAddClaim
    };
  },
  components: {
    IonPage,
    IonContent,
    AccountNavComponent,
    MainHeader,
    MainFooter,
    Pagination,
  },
});
